import React, { useState } from 'react'
import Navbar from "./Navbar";
import './Paidpromotionads.css';
import { FaFacebook, FaGoogle, FaInstagram, FaYoutube } from 'react-icons/fa';
import Footer from "./Footer";
import Instagrampaidads from './Instagrampaidads';
import Youtubepaidads from './Youtubepaidads';
import Googlepaidads from './Googlepaidads';

function Paidpromotionads() {

    const [showfacebook, facebookcontent] = useState(true);
    const [showinstagram, instagramcontent] = useState(false);
    const [showyoutube, youtubecontent] = useState(false);
    const [showgoogle, googlecontent] = useState(false);

    const handledesignclick = () => {
      facebookcontent(true);
      instagramcontent(false);
      youtubecontent(false);
      googlecontent(false);
    }

    const handledesignclick1 = () => {
      facebookcontent(false);
      instagramcontent(true);
      youtubecontent(false);
      googlecontent(false);
    }

    const handledesignclick2 = () => {
      facebookcontent(false);
      instagramcontent(false);
      youtubecontent(true);
      googlecontent(false);
    }

    const handledesignclick3 = () => {
      facebookcontent(false);
      instagramcontent(false);
      youtubecontent(false);
      googlecontent(true);
    }
    
    return (
        <div id="paidpromotion">
            <Navbar />

            <div className='main-content'>
                <div class='social-header-bg-paid'>
                   <div class='row text-contents-service'>
                        <div class='col-lg-6 col-md-12 col-sm-12 offset-lg-0 offset-md-0 offset-sm-0 text-content-service'>
                            <div className="left-content">
                                <h1>Paid Promotion Ads</h1>
                                <div class='line-service'></div>
                                <h4>The best Social Media paid advertising company in madurai, Tamil Nadu. Offers a number of Social Media paid
                                    promotion options to help businesses maintain their overall online purchase.</h4>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 text-image-service">
                            <img src="/Paidpromotion/Group 27.png" className='image-mike' height="600" width="700" />
                        </div> */}
                        <div className="col-lg-6 col-md-12 col-sm-12 text-image-services">
                            <img src="/Paidpromotion/Group 27.png" height="600" width="700" class="img-fluid" />
                        </div>

                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <ul className='centered-list'>
                                <li className='li'>Brand Awareness</li>
                                <li className='li'>Promote Your Local Business</li>
                                <li className='li'>Promote Your App</li>
                            </ul>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <ul className='centered-list'>
                                <li className='li'>Brand Awareness</li>
                                <li className='li'>Promote Your Local Business</li>
                                <li className='li'>Promote Your App</li>
                            </ul>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <ul className='centered-list'>
                                <li className='li'>Brand Awareness</li>
                                <li className='li'>Promote Your Local Business</li>
                                <li className='li'>Promote Your App</li>
                            </ul>
                        </div>
                    </div>
                </div>

<div className="image-container-paid">
    <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
            <div className="card-service" onClick={handledesignclick}>
                <div className='icon'>
                    <FaFacebook className="icons youtube black-icon m-2" />
                </div>
                <p>Facebook Paid Ads</p>
            </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
            <div className="card-service" onClick={handledesignclick1}>
                <div className='icon'>
                    <FaInstagram className="icons youtube black-icon m-2" />
                </div>
                <p>Instagram Paid Ads</p>
            </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
            <div className="card-service" onClick={handledesignclick2}>
                <div className='icon'>
                    <FaYoutube className="icons youtube black-icon m-2" />
                </div>
                <p>youTube Paid Ads</p>
            </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
            <div className="card-service" onClick={handledesignclick3}>
                <div className='icon'>
                    <FaGoogle className="icons youtube black-icon m-2" />
                </div>
                <p>Google Paid Ads</p>
            </div>
        </div>
    </div>
</div>


  { showfacebook && (
    <div className='container-paid'>
    <div className='pagein-content'>
        <h3>Facebook Paid Ads</h3>

<div className='second-main'>
<div class="row row-cols-1 row-cols-md-3 g-4 second-main2">
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (1).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Amman Restaurant</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (2).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Balagopalan Jewellery</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (3).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">We Craft floors</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (4).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Senthil Jewellery</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (5).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">MK oil Mills</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (6).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Hi Bro Mens Madurai</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (7).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Varshan Enterprises</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook.png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Saaral bakes cakes sweets</h5>
<p class="card-text"></p>
</div>
</div>
</div>
</div>
</div>

  </div>
</div>
  )}

  {showinstagram && (
    < Instagrampaidads />
  )}

  {showyoutube && (
    < Youtubepaidads />
  )}

  {showgoogle && (
    < Googlepaidads />
  )}

                < Footer />

            </div>
        </div>
    )
}

export default Paidpromotionads
