import React from 'react'

function Instagrampaidads() {
  return (
    <div>
        <div className='container-paid'>
    <div className='pagein-content'>
        <h3>Instagram Paid Ads</h3>

<div className='second-main'>
<div class="row row-cols-1 row-cols-md-3 g-4 second-main2">
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Instagram-paid/Balagopalan Jwellery Mart copy.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Balagopalan Jewellery Mart</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Instagram-paid/Hi Bro Mens Madurai copy.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Hi Bro Men's Wear</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Instagram-paid/Kerala Furniture Gallery copy.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Kerala Furniture</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Instagram-paid/RMR Silks copy.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">RMR Silks</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Instagram-paid/We Craft copy.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">We Craft</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
{/* <div class="card">
<img src="/Facebook - Client/Facebook (6).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Hi Bro Mens Madurai</h5>
<p class="card-text"></p>
</div>
</div> */}
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
{/* <div class="card">
<img src="/Facebook - Client/Facebook (7).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Varshan Enterprises</h5>
<p class="card-text"></p>
</div>
</div> */}
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
{/* <div class="card">
<img src="/Facebook - Client/Facebook.png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Saaral bakes cakes sweets</h5>
<p class="card-text"></p>
</div>
</div> */}
</div>
</div>
</div>

    </div>
</div>
    </div>
  )
}

export default Instagrampaidads
