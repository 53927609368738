import React, { useRef, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import './Client.css';
import { fetcheighthsection, base_api } from './Axios/Axios';
import Aos from "aos";
import 'aos/dist/aos.css';


function Client() {
  const owlCarouselRef = useRef(null);
  const [margin, setMargin] = useState(30); // Initial margin

  const [eighthsection, setEighthsection] = useState([]);
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
    try {
      const eighthsectiondata = await fetcheighthsection();
      setEighthsection(eighthsectiondata)
    } catch (error) {
      setIsBackendError(true);
    }
  };

  fetchData();
}, []);

useEffect(() => {
  Aos.init({ duration: 2000 });
}, []);


  // Owl Carousel Settings
  const options = {
    margin: margin,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    navText: ["<", ">"],
    smartSpeed: 1000,
    responsive: {  
      0: { items: 1 },
      375: { items: 1 },
      400: { items: 2 },
      600: { items: 2 },
      700: { items: 3 },
      1000: { items: 4 },
      1200: { items: 5 },
      1400: { items: 6 }
    },
    loop: true
  };

  useEffect(() => {
    // Update margin dynamically based on screen width
    const updateMargin = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 400) {
        setMargin(10);
      } else {
        setMargin(30);
      }
    };

    updateMargin(); // Initial update
    window.addEventListener('resize', updateMargin); // Add listener for window resize

    return () => {
      window.removeEventListener('resize', updateMargin); // Cleanup
    };
  }, []);

  const handlePrev = () => {
    owlCarouselRef.current.prev();
  };

  const handleNext = () => {
    owlCarouselRef.current.next();
  };
 
  const renderClientcontent = () => {
    if (isBackendError || !eighthsection.length) {
  return (
    <div>
      <div className='mt-5'>
      <h2 className='Heading-client'>Happy Clients</h2>
        <div className='d-flex justify-content-center ms-3'>
          <OwlCarousel ref={owlCarouselRef} className="slider-items owl-carousel" {...options}>
            {/* <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client1.png" alt="Client 1"/></div> */}
            <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client2.png" alt="Client 2"/></div>
            {/* <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client3.png" alt="Client 3"/></div> */}
            <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client4.jpg" alt="Client 4"/></div>
            {/* <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client5.jpg" alt="Client 5"/></div> */}
            <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client6.png" alt="Client 6"/></div>
            <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client7.png" alt="Client 7"/></div>
            <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client8.png" alt="Client 8"/></div>
            <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/clinet9.jpg" alt="Client 9"/></div>
            <div className="item" style={{cursor: "pointer"}}><img src="/Clients_logo/client10.png" alt="Client 10"/></div>
          </OwlCarousel>
        </div>
      </div>

    <div className='foot d-flex justify-content-center mt-3'>
        <FaArrowCircleLeft className="icon" onClick={handlePrev} /> 
        <FaArrowCircleRight className="icon" onClick={handleNext}/>
    </div>
      </div>
  );
} else {
  return (
<div>
<div className='mt-5'>
          <h2 className='Heading-client'>{eighthsection[0]?.title}</h2>
          <div className='d-flex justify-content-center ms-3'>
            <OwlCarousel ref={owlCarouselRef} className="slider-items owl-carousel" {...options}>
              {eighthsection.map((data) => (
                <div className="item" style={{ cursor: "pointer" }} key={data.id}>
                  <img src={`${base_api}${data.logo}`} alt={`Client ${data.id}`} />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>

        <div className='foot d-flex justify-content-center mt-3'>
    <div className='foot d-flex justify-content-center mt-3'>
        <FaArrowCircleLeft className="icon" onClick={handlePrev} /> 
        <FaArrowCircleRight className="icon" onClick={handleNext}/>
    </div>
      </div>
</div>
  );
}
};


return (
  <div>
    {renderClientcontent()}
  </div>
);
}

export default Client;
