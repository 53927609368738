import React, { useEffect, useState } from "react";
import Aos from "aos";
import 'aos/dist/aos.css';
import { fetchfifthsection, fetchfifthsectioncontent, base_api } from "./Axios/Axios";


function Ourservice() {

    const [fifthsection, setEighthsection] = useState([]);
    const [fifthsectionContent, setFifthsectionContent] = useState([]);
    const [isBackendError, setIsBackendError] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
      try {
        const eighthsectiondata = await fetchfifthsection();
        const fifthsectiondata = await fetchfifthsectioncontent();
        setEighthsection(eighthsectiondata)
        setFifthsectionContent(fifthsectiondata)
      } catch (error) {
        setIsBackendError(true);
      }
    };

    fetchData();
}, []);

    useEffect(() => {
      Aos.init({ duration: 2000 })
    }, []);

    const rendereighthsection = () => {
      const defaultData = {
        title: 'Our Works',
        subtitle: 'We can help business achieve their <br /> digital marketing brands and goals',
      };
      if (isBackendError || !fifthsection.length || !fifthsectionContent.length) {

  return (
      <div className="ourservice-header">
        <div className="ourservice-content">
          
        <div className="ourservice-head">
          <img src="Imagefile/alert.png" className="ourservice-alert"/>
          <h3 className="pt-3">Our services</h3>
          <p className="para">
            We can help business achieve their
            <br /> digital marketing brands and goals
          </p>
          <img src="Imagefile/seo.png" className="ourservice-seo" />
        </div>

          <div className="ourservice-box">
            <div className="ourservices-box1">
              <img src="Imagefile/5thsec_seo.png" className="service-png" />
              <div className="service-content">
                <h4>SEO Optimization
                {/* <a href="#">Chat with us</a> */}
                </h4>
                <p>
                  {" "}
                  We know what customers are searching for and we know
                  how to get them to your web page.
                </p>
              </div>
            </div>
            

            <div className="ourservices-box1">
              <img src="Imagefile/5thsec_smm.png" className="service-png" />
              <div className="service-content">
                <h4>Social Media Marketing</h4>
                <p>
                  {" "}
                  We are glad to help enlarge your reach,
                  maximize your Web appearance.
                </p>
              </div>
            </div>

            <div className="ourservices-box1">
              <img src="Imagefile/5thsec_gd.png" className="service-png" />
              <div className="service-content">
                <h4>Graphic Design</h4>
                <p>
                  {" "}
                  Good graphic design helps create a 
                  positive Lister experience for your
                  audience.
                </p>
              </div>
            </div>

            <div className="ourservices-box1">
              <img src="Imagefile/5thsec_da.png" className="service-png" />
              <div className="service-content">
                <h4>Digital Advertisement</h4>
                <p>
                  {" "}
                  Ignoring digital ads is like opening a 
                  business without telling anyone. Let's
                  create it with us.
                </p>
              </div>
            </div>

            <div className="ourservices-box1">
              <img src="Imagefile/5thsec_ve.png" className="service-png" />
              <div className="service-content">
                <h4>Video Editing</h4>
                <p>
                  {" "}
                  A video without a storyboard is like a
                  House without a foundation. Don't worry,
                  we will build it strong.
                </p>
              </div>
            </div>

            <div className="ourservices-box1">
              <img src="Imagefile/5thsec_cm.png" className="service-png" />

              <div className="service-content">
                <h4>Content Marketing</h4>
                <p>
                  {" "}
                Let us help you attract and retain more web
                traffic with ace contents that sells!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
} else {
    return fifthsection.map((item) => (
<div className="ourservice-header">
  <div className="ourservice-content">
<React.Fragment key={item.id}>
    <img src={`${base_api}${item.png1}`} className="ourservice-alert"/>
    <h3 dangerouslySetInnerHTML={{ __html: item.title || defaultData.title }}></h3>
    <p className="para" dangerouslySetInnerHTML={{ __html: item.content || defaultData.subtitle }} />
    <img src={`${base_api}${item.png2}`} className="ourservice-seo" />
  </React.Fragment>

  <div className="ourservice-box">
    {fifthsectionContent.map((item) => (
      <div className="ourservices-box1">
        <img src={`${base_api}${item.image}`} className="service-png" />
        <div className="service-content">
          <h4 dangerouslySetInnerHTML={{ __html: item.heading }} />
          <p dangerouslySetInnerHTML={{ __html: item.subcontent }} />
        </div>
      </div>
      ))}
    </div>
  </div>
</div>
    ));
}
};

return (
  <div>
    {rendereighthsection()}
  </div>
);
}


export default Ourservice;

