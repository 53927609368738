import React from 'react'
import './Youtubepaidads.css'

function Youtubepaidads() {
  return (
    <div>
       <div className='container-paid'>
    <div className='pagein-content'>
        <h3>Youtube Paid Ads</h3>

<div className='second-main'>
<div class="row row-cols-1 row-cols-md-3 g-4 second-main2">
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Imagefile/photo_2024-09-20_17-33-27.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Sri Balagopalan Jewellery</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Imagefile/photo_2024-09-20_17-33-48.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Vijay Tex</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Imagefile/photo_2024-09-20_17-33-53.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">MK Oil Mills</h5>
<p class="card-text"></p>
</div>
</div>
</div>


<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Imagefile/photo_2024-09-20_17-33-58.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Senthil Jewellery Mart</h5>
<p class="card-text"></p>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Imagefile/photo_2024-09-20_17-34-06.jpg" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Hi Bro Men's Wear</h5>
<p class="card-text"></p>
</div>
</div>
</div>
{/* <div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (6).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Hi Bro Mens Madurai</h5>
<p class="card-text"></p>
</div>
</div>
</div> */}
{/* <div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook (7).png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Varshan Enterprises</h5>
<p class="card-text"></p>
</div>
</div>
</div> */}
{/* <div class="col-lg-4 col-md-6 col-sm-12">
<div class="card">
<img src="/Facebook - Client/Facebook.png" class="card-img-top" height="300" width="200" alt="..."/>
<div class="card-body">
<h5 class="card-title">Saaral bakes cakes sweets</h5>
<p class="card-text"></p>
</div>
</div>
</div> */}
</div>
</div>

    </div>
</div>
    </div>
  )
}

export default Youtubepaidads
