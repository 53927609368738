import React from "react";
import {FaYoutube, FaWhatsapp, FaLinkedin, FaFacebook, FaInstagram} from 'react-icons/fa';


function Footer() {
  return (
    <div className="footer-header">
      <div className="footer-content">
        <div className="footer-logo">
          <a href="/">
          <img src="/Imagefile/footer_logo.png" className="footer-logo-png" />
          </a>
          {/* <div>
                  <ul className="social">
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-facebook"></i>
                        <FaFacebook className="icons youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-instagram"></i>
                        <FaYoutube className="icons youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-linkedin"></i>
                        <FaInstagram className="icons youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-youtube"></i>
                        <FaLinkedin className="icons youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-square-x-twitter"></i>
                        <FaWhatsapp className="icons youtube" />
                      </a>
                    </li>
                  </ul>
          </div> */}
          <p className="footer-content-p">
          Phoenix Marketing is a leading advertising agency in Madurai that offers a wide range of Digital advertising services like Search Engine Marketing, Social Media Marketing, Web Design & Development Etc.
          </p>
        </div>

        <div className="footer-links">
          <div className="footer-quicklink1">
            <ul className="footer-ql-ul">
              <p>Quick links</p>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/webdevelopement">Service</a>
              </li>
              {/* <li>
                <a href="/portfolio">Portfolio</a>
              </li> */}
              <li>
                <a href="/contact">Contact</a>
              </li>
              {/* <li>
                <a href="#">Contact us</a>
              </li> */}
            </ul>
          </div>

          <div className="footer-quicklink2">
            <ul className="footer-ql-ul">
              <p>Our Services</p>
              <li>
                <a href="/digitalmarketing">Digital Marketing</a>
              </li>
              <li>
                <a href="/paidpromotionads">Paid Promotions ads</a>
              </li>
              <li>
                <a href="/webdevelopement">Web Development</a>
              </li>
              <li>
                <a href="/socialmediamarketing">Social Media Marketing</a>
              </li>
            </ul>
          </div>

<div class="footer-quicklink3">
    <ul class="footer-ql-ul">
      <p>Contact Us</p>
      <li>
        <a href="tel:+91 9025428057" target="_blank"
                    rel="noopener noreferrer">Mobile: +91 90254 28057</a>
      </li>
      <li>
        <a href="mailto:phoenixmarketing.jp@gmail.com" target="_blank"
                    rel="noopener noreferrer">Email: phoenixmarketing.jp@gmail.com</a>
      </li>
      <li className="address-footer">
        <p className="add-content">Phoenix Marketing
No 2, First Floor,
DRO Colony Main Road, Near Indian Bank , K.Pudur, Madurai -625007.</p>
      </li>
      {/* <li>
        <a href="#">Portfolio</a>
      </li>
      <li>
        <a href="#">Contact us</a>
      </li> */}
    </ul>

    <div>
                  <ul className="social d-flex justify-content-evenly">
                    <li>
                      <a href="https://www.facebook.com/Phoenixmarketing.in" className="linkedin-icon" target="_blank"
                    rel="noopener noreferrer">
                        {/* <i class="fa-brands fa-facebook"></i> */}
                        <FaFacebook className="icons youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@phoenixmarketing-in/" className="linkedin-icon" target="_blank"
                    rel="noopener noreferrer">
                        {/* <i class="fa-brands fa-instagram"></i> */}
                        <FaYoutube className="icons youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/phoenixmarketing.in/" className="linkedin-icon" target="_blank"
                    rel="noopener noreferrer">
                        {/* <i class="fa-brands fa-linkedin"></i> */}
                        <FaInstagram className="icons youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/phoenix-marketing-in/" className="linkedin-icon" target="_blank"
                    rel="noopener noreferrer">
                        {/* <i class="fa-brands fa-youtube"></i> */}
                        <FaLinkedin className="icons youtube"/>
                      </a>
                    </li>
                  </ul>
          </div>
  </div>


          
        </div>
      </div>

      <div className="copyright">
        <p>Copyright &#169; 2024 Phoenix Marketing.com All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
