// import React from 'react'

// function Watsapplink() {
//     const handleClick = () => {
//         window.open('https://wa.me/9876543210', '_blank'); // Opens in a new tab
//     };

//     return (
//         <div>
//             <a href="https://wa.me/9876543210" target="_blank" rel="noopener noreferrer">
//                 <img src="./images/whatsapp.png" alt="WhatsApp" onClick={handleClick} />
//             </a>
//         </div>
//     );
// }

// export default Watsapplink

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FaWhatsapp } from 'react-icons/fa'


const Watsapplink = () => {
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowIcon(true);
      } else {
        setShowIcon(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const openWhatsApp = () => {
    // window.open("https://api.whatsapp.com/send?phone=9876543210", "_blank");
  };

  return (
    <div className="whatsapp-link">
      {showIcon && (
        <div className="whatsapp-icon" onClick={openWhatsApp}>
          {/* <FontAwesomeIcon icon={faWhatsapp} /> */}
          {/* <i class="fa-brands fa-whatsapp"></i> */}
   

        <li className="whatsapp">
            <a
              href="https://wa.me/9025428057"
              rel="noopener noreferrer"
              target="_blank"
            >
              {/* <i className="fab fa-whatsapp"> </i>  */}
              <FaWhatsapp className="Watsapp" />
              </a>
          </li>
        </div>
      )}
    </div>
  );
};

export default Watsapplink;
